import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import TextRenderer from '../components/TextRenderer'
import Seo from '../components/Seo'

const StyledTextRenderer = styled(TextRenderer)`
    padding: 15rem 3rem 12rem 3rem;

    text-align: center;

    color: #fff;

    ${props => props.theme.above.desktop`
        padding: 45rem 6rem;
    `}
`

const NotFoundTemplate = ({
    data: {
        contentfulNotFound: {
            title,
            content
        }
    }
}) => {
    return (
        <>
            <Seo
                title='404'
            />
            <StyledTextRenderer data={content} />
        </>
    )
}

export const query = graphql`
	query($id: String!) {
		contentfulNotFound(id: {eq: $id}) {
            title
            content {
                json
            }
        }
	}
`

export default NotFoundTemplate
